.main {
  background-color: #04050e;
  color: #ffffff;
  font-family: HalvarBreit;
}

.main-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 38px 0;
}

.main-image {
  position: relative;
  transform: rotate(0deg);
  margin: 10px auto -60px;
  z-index: 0;
  width: 210px;
}

.main-text {
  z-index: 1;
}

.main-header {
  max-width: 263px;
  font-size: 24px;
  line-height: min 25px;
  border: none;
}

.text {
  font-size: 14px;
  line-height: 14.5px;
  opacity: .7;
  width: 273px;
}

.button {
  font-family: HalvarBreit;
  width: 300px;
  height: 60px;
  background-color: #04050e;
  color: #ffffff;
  border-width: .7px;
  border-color:#FFCD2A;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  margin: 15px 0;
  cursor: pointer;
}

.anchor {
  text-decoration: none;
  color: #ffffff;
  height: 100%;
  width: 100%;
}

.mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 38px 100px;
}
/*настроить отступы с начала страницы у блока мэйн и блок миссия */
.mission-header {
  margin: 0;
  font-size: 20px;
  line-height: 21px;
}

.mission-purpose {
  margin: 20px auto 15px;
  font-size: 24px;
  line-height: 25px;
  max-width: 250px;
}

.button-block {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}


.description {
  margin: 70px 0 70px;
}

.description-container {
  background-color: #21232E;
  border-radius: 10px;
  width: 300px;
  height: 388px;
  margin-bottom: 30px;
}

.description-image {
  width: 120px;
  height: 120px;
  margin: 25px auto;
}

.description-header {
  font-size: 20px;
  line-height: 21px;
  margin: 0 auto 25px;
  max-width: 283px;
}


.description-list {
  list-style-type: disc;
  font-size:14px;
  line-height: 15px;
  width: 240px;
  text-align:start;
  opacity: 70%;
}

.description-item {
  padding-bottom: 15px;
}

.client-purpose{
  margin: 25px auto;
  font-size: 24px;
  line-height: 25px;
  width: 273px;
}

.client-text{
  font-size: 14px;
  line-height: 14px;
  opacity: 70%;
  text-align: start;
  width: 273px;
}

.client-envelope {
  width: 200px;
  height: 288px;
}

@media screen and (min-width: 766px) {
.main {
  margin-top: 70px;
}

.main-block {
  flex-direction: row;
}

.main-image {

  order: 2;
  width: 400px;
  margin: 0 auto;
}


.main-header {
  max-width: 512px;
  font-size: 28px;
  line-height: 35px;
  text-align: left;
}

.text {
  text-align: left;
}

.mission-header {
  font-size: 22px;
}

.mission-purpose {
  margin: 20px auto 15px;
  font-size: 24px;
  max-width: 800px;
}

}




@media screen and (min-width: 1438px) {

.main {
  margin: 90px 130px 0;
}

.main-block {
  margin: 0;
  justify-content: space-between;
}

.main-image {
  width: 435px;
  margin: 0;
}

.main-header {
  font-size: 48px;
  line-height: 50px;
  margin-bottom: 20px;
}

.text {
  font-size: 16px;
  line-height: 17px;
  width: 322px;
}

.mission {
  margin: 120px 0 150px;
}

.mission-header {
  font-size: 40px;
  line-height: 42px;
}

.mission-purpose {
  font-size: 48px;
  line-height: 50px;
  max-width: 1000px;
}

.button {
  width: 340px;
  height: 61px;
}

.button-block {
  flex-direction: row;
  width: 1150px;
  margin-top: 60px;
}

.description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1180px;
  margin: 150px 0 150px;
}

.description-container {
  width: 367px;
  height: 470px;
}

.description-image {
  width: 160px;
  height: 160px;
  margin: 25px auto;
}

.description-header {
  font-size: 24px;
  line-height: 25px;
  margin: 0 auto 25px;
  max-width: 345px;
}

.description-list {
  font-size: 16px;
  line-height: 18px;
  width: 341px;
}

.client {
  display: flex;
  flex-direction: row;
  width: 1074px;
  justify-content: space-between;
}

.client-envelope {
  width: 377px;
  height: 541px;
}

.client-description {
  margin: auto 0 auto;
}
.client-purpose{
  margin: 25px auto;
  font-size: 48px;
  line-height: 50px;
  width: 547px;
  text-align: start;
}

.client-text{
  font-size: 16px;
  line-height: 17px;
  width: 467px;
}





}