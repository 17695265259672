

.navigation-menu {
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  transition: 0.55s opacity, 0.55s visibility;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #04050E;
  top:0;
  right: 0;
  z-index: 3;
  padding: 50px 0;
}

 .menu__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 96px;
}

 .navigation-menu-list:hover {
  cursor: pointer;
  text-decoration: underline 5px solid #FFFFFF;
  text-underline-offset: 7px;
}

 .navigation-menu-list {
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  color: #FFFFFF;
  margin-bottom: 24px;
}

.navigation__list {
  text-decoration: none;
  color: #FFFFFF;
  font-size: 26px;
  line-height: 32px;
  margin: 40px auto;
}

.navigation__link {
  display: flex;
  flex-direction: column;
  align-items: center;

}
 .menu__account-link {
  font-size: 14px;
  text-decoration: none;
  color: #FFFFFF;
}

.menu__account-container {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

 .account-image {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  background-color: #313131;
  border-radius: 6px;
}

/*Burger Menu Button*/
#menu {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

#menu:checked ~ .navigation-menu {
  visibility: visible;
  opacity: 1;
}

body.active {
  overflow: hidden;
}


.checkbox-button {
  position: relative;
  display: flex;
  width: 17px;
  height: 17px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 4;
}

.line {
  position: absolute;
  width: 20px;
  margin: 0 0 4px -19px;
}

 .line-main::before,.line-main::after,
.line-split::before,.line-split::after {
  content: '';
  position: absolute;
  width: inherit;
  height: 3px;
  background-color: #FFFFFF;
  transition: transform .5s;
}


.line-main::before {
  transform: translateY(-7px);
}

.line-main::after {
  transform: translateY(7px);
}

.line-split::before,
.line-split::after {
  width: 10px;
  transition: transform .5s, opacity .5s;
}

.line-split::after {
  transform: translateX(10px);
}

#menu:checked + label > .line-main::before {
  transform: rotate(45deg);
  background-color: #FFFFFF;
}

#menu:checked + label > .line-main::after {
  transform: rotate(-45deg);
  background-color: #FFFFFF;
}

#menu:checked + label > .line-split::before {
  transform: translateX(-10px);
  opacity: 0;
}

#menu:checked + label > .line-split::after {
  transform: translateX(20px);
  opacity: 0;
}

.navigation-widescreen {
  display: none;
}
.navigation {
  /* width: 100%; */
  display: flex;
  /* justify-content: start; */
  flex-direction: column;
  align-items: center;
  margin-top: 0 auto;
}

@media screen and (min-width: 768px) {
  .navigation-menu {
    width: 68vw;
    padding: 90px 0;
  }
  .menu__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
  }

}

@media screen and (min-width: 1024px) {
  .checkbox-button {
    display: none;
  }
  #menu:checked ~ .navigation-menu {
    display: none;
  }
  .navigation-widescreen {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 44px;
  }
/*   .navigation__link {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
/*   .navigation__list {
    font-size: 13px;
    line-height: 18px;
    text-decoration: none;
    color: #FFFFFF;
    padding-right: 16px;
  } */
}
