.partner-card_list{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 70px auto 100px;
  overflow-x: auto;
}

.partner-card{
  width: 100%;
  height: 100%;
  padding-right: 20px;
}

.test{
  width: 95px;
  height: 53px;
}

.partner-card_list::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 1438px) {

  .test{
    width: 200px;
    height: 115px;
  }
}