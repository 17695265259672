@font-face {
  font-family: 'HalvarBreit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('HalvarBreit'),
   url('../../fonts/HalvarBreit-Lt.woff2') format(woff2)
}
.App {
  text-align: center;
  font-family: HalvarBreit;
  margin: 0 auto;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

