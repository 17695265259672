.contacts{
  background-color: #04050e;
  color: #ffffff;
  font-family: HalvarBreit;
}

.contacts-header{
  margin: 20px auto;
  text-align: left;
  font-size: 24px;
  line-height: 25px;
  width: 273px;
}

.contacts-text{
  font-size: 14px;
  text-align: start;
  line-height: 14px;
  margin: 0 auto 30px;
  max-width: 273px;
}

.contacts-office{
  max-width: 273px;
  margin-bottom: 100px;
}

.contacts-info{
  text-align: start;
  margin: 0 auto;
  width: 273px;
}

.contacts-container{
  margin: 30px auto;
}

.contacts-header{
  font-size: 24px;

}

.contacts-head{
  margin: 0;
  font-size: 20px;
}

.contacts-line{
  font-size: 14px;
  opacity: 70%;
  padding-top: 20px;
  margin: 0;
}

.contacts-telephone{
  display: flex;

}

.contacts-tg{
  width: 30px;
  height: 30px;
  padding-left: 20px;
}

.contacts-map{
  width: 273px;
  height: 200px;
  border:0;
  border-radius: 10px;
}




@media screen and (min-width: 1438px) {
  .contacts {
    margin: 0 130px 0;
  }

  .office-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  .contacts-header{
    font-size: 48px;
    line-height: 50px;
    width: 490px;

  }

  .contacts-text{
    font-size: 16px;
    line-height: 17px;
    margin: 0 0 30px;
    max-width: 459px;
  }

  .contacts-office{
    max-width: 660px;
    height: 640px;
    margin-bottom: 150px;
  }

  .contacts-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 980px;
  }

  .info-container {
    order: 2;
    width: 230px;
  }

  .contacts-telephone {
    display: flex;
    flex-direction: row;
  }

  .contacts-header{
    margin: 0 0 30px;
    font-size: 48px;
    line-height: 50px;
    width: 273px;
  }

  .contacts-head{
    font-size: 20px;
    line-height: 21px;
  }

  .contacts-line{
    font-size: 16px;
    line-height: 17px;

    padding-top: 30px;
    margin: 0;
  }

  .contacts-map {
    width: 610px;
    height: 425px;
  }

  .partners {
    display: flex;
    font-size: 48px;
    line-height: 50px;
    margin-bottom: 0;
    justify-self: flex-start;
  }
}