.header {
  font-size: 32px;
  display: flex;
  justify-content: space-between;
  border-top: .3px solid;
  border-bottom: .3px solid;
  border-color: grey;
  width: 100%;
}

.header_menu {
  width: 70px;
  margin: auto;
}

.logo_element {
  height: 53px;
  width: 93px;

}

.image_container {
  width: 70px;
  background-color: #04050E;
}

.header_button{
  background-color: #04050E;
  border-width: 0;
  margin: 15px 0;
  cursor: pointer;

}

.call_element {
  height: 24px;
  width: 24px;

}

.logo_image {
  width: 245px;
  border-left: .3px solid;
  border-right: .3px solid;
  border-color:grey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.descktop_menu {
  display: none;
}

@media screen and (min-width: 766px) {

  .header {
    height: 80px;
    position: fixed;
    z-index: 2;
    background-color: #04050E;
  }


  .header_menu {
    display: none;
  }
  .logo_image {
    border: 0;
  }
  .image_container {
    display: none;
  }
  .descktop_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 525px;
    margin-right: 5vw;
  }

  .logo_element {
    width: 100px;
  }

  .descktop_anchor {


  }

  .descktop_button {
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
    background-color: #04050e;
    border: 0;
    font-size: 14px;
    line-height: 15px;
    font-family: HalvarBreit;
  }

}

@media screen and (min-width: 1438px) {
  .logo_image {
    margin-left: 130px;
    justify-content: start;
  }

  .descktop_menu {
    margin-right: 130px;
  }
}