.footer{
  color: #FFFFFF;

}

.footer-module{
  border: .3px solid;
  border-left: #FFFFFF;
  border-right: #FFFFFF;
  opacity: 70%;
}

.footer-header{
  margin: 30px auto;
  text-align: start;
  width: 273px;
  font-size: 14px;
}

.footer-social{
  display: flex;
  justify-content: space-around;
  width: 273px;
  margin: 0 auto;
}

.footer-button{
  width: 125px;
  height: 50px;
  background-color: #04050e;
  color: #ffffff;
  border-width: 1px;
  border-color:#FFFFFF;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  margin: 15px 0;
  cursor: pointer;
}

.footer-image{
  width: 20px;
  height: 20px;
}

.footer-copyright{
  font-size: 10px;
  opacity: 70%;
  width: 274px;
  margin: auto;
}


@media screen and (min-width: 1438px) {

  .footer-header{
    font-size: 20px;
    line-height: 21px;
    width: 310px;
  }

  .footer-social{
    width: 350px;

  }

  .footer-button{
    width: 130px;
    height: 60px;
  }

  .footer-image{
    width: 25px;
    height: 25px;
  }


  .footer-copyright{
    font-size: 14px;
    width: 400px;
  }

}